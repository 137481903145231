<template>
  <v-app>
    <v-app-bar
      app
      color="#0C2D48"
      dark
    >
      <div class="d-flex align-center">
        <RouterLink to="/">
            <img src="@/assets/icon.png" height="50" width="50" alt="SportsBytez" />
        </RouterLink>
        <h1 style="margin-left: 6px;">SportsBytez DFS Lineup Optimizer</h1>
      </div>
      <v-toolbar-items class="toolbar-nav hidden-xs-only">
        <v-btn
          v-for="item in visibleItems"
          :key="item.title"
          :to="item.path">
          <v-icon v-if="item.icon" left dark>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn color="primary" href="https://discord.gg/HE9TyNKFkW" target="_blank" style="margin-right: 20px;">
        <img src="@/assets/discord.png" height="28" width="28" />
        Join the Discord Server!
      </v-btn>
      <UserMenu v-if="isAuthenticated" />
      <a v-else class="link" @click.prevent="handleLogin">Login</a>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <v-snackbar
      top
      centered
      :color="snackbarColor"
      v-model="snackbar"
      :timeout="snackbarTimeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-footer app absolute>
        <v-row class="footer-row">
            <v-col cols="12" md="4">
                <a href="/terms">Terms and Conditions</a>
                <a href="/privacy">Privacy Policy</a>
            </v-col>
            <v-col cols="12" md="4" style="text-align: center; font-size: 16px;">
                <v-dialog
                v-model="dialog"
                width="500"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <strong><a v-bind="attrs" v-on="on">Feedback / Report Issue</a></strong>
                    </template>

                    <v-card>
                        <v-card-title class="text-h5 primary lighten-2">
                            Feedback / Report Issue
                        </v-card-title>

                        <v-card-text style="margin-top: 10px;">
                            Enter the feedback or issue you want to report below. If you are reporting an issue please be as specific as possible so we can get to the bottom of the issue!
                            <div style="margin-top: 10px;">
                                <v-textarea
                                    filled
                                    name="input-7-4"
                                    label="Feedback"
                                    v-model="feedback"
                                ></v-textarea>
                            </div>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="sendFeedback"
                            >
                                Send
                            </v-btn>
                            <v-btn
                                color="secondary"
                                text
                                @click="dialog = false"
                            >
                                Cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-col cols="12" md="4" style="text-align: right;">
                <small>&copy; Copyright {{ new Date().getFullYear() }}, SportsBytez</small>
            </v-col>
        </v-row>
    </v-footer>
  </v-app>
</template>
<script>
import SharedRestClient from './services/SharedRestClient';
import UserMenu from './components/shared/UserMenu.vue';
import { getInstance } from './auth0-plugin';

export default {
  name: 'App',
  components: {
    UserMenu
  },
  data: function() {
    return {
      dialog: false,
      feedback: null,
      snackbar: false,
      snackbarText: null,
      snackbarColor: 'success',
      snackbarTimeout: 5000,
      menuItems: [
        { title: 'NFL Optimizer', path: '/football', visible: () => true },
        { title: 'MLB Optimizer', path: '/baseball', visible: () => true },
        { title: 'NFL Team Rosters', path: '/football/team-rosters', visible: () => this.isAdmin },
        { title: 'NFL Backtesting', path: '/football/backtesting', visible: () => this.isAdmin },
        { title: 'MLB Backtesting', path: '/baseball/backtesting', visible: () => this.isAdmin }
      ]
    }
  },
  created() {
    const authService = getInstance();

    // Periodically check and renew the token
    setInterval(async () => {
        try {
            var token = await authService.getAccessTokenSilently();
            this.$store.commit('SET_AUTH_TOKEN', token);
        } catch (error) {
            console.error('Silent token renewal failed:', error);
        }
    }, 60000); // Check every 60 seconds
  },
  mounted() {
    this.$root.$on('snackbar', (data) => this.showSnackbar(data));
  },
  computed: {
    isAdmin() {
      return this.isAuthenticated && this.$auth0.user[process.env.VUE_APP_AUTH0_ROLES] && this.$auth0.user[process.env.VUE_APP_AUTH0_ROLES].includes('admin');
    },
    isAuthenticated() {
      return this.$auth0.isAuthenticated;
    },
    visibleItems() {
      return this.menuItems.filter(i => i.visible());
    }
  },
  methods: {
    handleLogin() {
        this.$auth0.loginWithRedirect({
            appState: {
                targetPath: "/",
            },
        });
    },
    showSnackbar(data) {
      this.snackbarText = data.text;
      this.snackbarColor = data.color;
      this.snackbar = true;
    },
    sendFeedback() {
      SharedRestClient.user()
        .sendFeedback({ feedback: this.feedback })
        .then(() => {
            this.showSnackbar({
                text: 'Your feedback has been submitted. Thanks for helping us out!',
                color: 'success',
            });
            this.feedback = null;
            this.dialog = false;
        });
    }
  }
};
</script>

<style scoped>
div.toolbar-nav {
  margin-left: 40px;
}
h1 {
  font-size: 24px;
}
span.v-btn__content img {
  margin-right: 10px;
}
.v-footer {
    height: 50px;
    background-color: rgb(12, 45, 72) !important;
    padding: 5px 0;
    color: white !important;
}
.v-footer a {
    color: white;
    text-decoration: none;
}
.v-footer a:hover {
    text-decoration: underline;
}
div.footer-row {
    margin: 0 2em;
    font-size: 14px;
}
div.footer-row a {
    margin: 0 1em;
}
a.link {
  color: white !important;
  text-decoration: none;
}
a.link:hover {
  text-decoration: underline;
}
</style>
