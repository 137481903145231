<template>
  <div>
      <v-row>
          <v-col cols="10">
              <v-btn
                  v-for="filter in positionFilters"
                  :key="filter"
                  class="ma-2"
                  :outlined="filter !== positionFilter"
                  color="blue"
                  @click="positionFilter = filter"
              >
                  {{ filter }}
              </v-btn>
              <v-checkbox
                  v-model="excludeNonProbables"
                  label="Exclude Non Probables"
                  title="Exclude Non Probable players from display and lineup consideration">
              </v-checkbox>
              <v-checkbox
                  v-model="hideNoProjections"
                  label="Hide players with 0 projected points">
              </v-checkbox>
              <div class="search-box">
                  <v-text-field
                          label="Player Search"
                          v-model="inputFilter"
                          v-on:keyup.enter="searchFilter"
                          v-on:blur="searchFilter"
                          append-icon="mdi-close"
                          @click:append="clearSearchFilter">
                  </v-text-field>
              </div>
          </v-col>
          <v-col cols="2">
              <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          class="ma-2 btn-icon-action"
                          color="green"
                          v-bind="attrs"
                          v-on="on"
                          @click="resetExposures"
                          icon
                      >
                          <v-icon>
                              mdi-lock-reset
                          </v-icon>
                      </v-btn>
                  </template>
                  <span>Reset all projections and exposures back to default values (exposures 0% minimum, 100% maximum)</span>
              </v-tooltip>
          </v-col>
      </v-row>
      <v-data-table
          fixed-header
          dense
          height="60vh"
          :headers="headers"
          :items="viewProjections"
          :items-per-page="50"
          :item-class="rowClass"
          :footer-props="footerProps"
          :loading="loadingProjections"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          loading-text="Loading projections..."
          no-data-text="No data available for this slate..."
          class="elevation-1 top-margin proj-table"
      >
          <template v-for="h in headers" v-slot:[`header.${h.value}`]="">
              <span :key="h.text">
                  <v-tooltip top v-if="h.tooltip">
                      <template v-slot:activator="{ on }">
                          <span v-on="on">{{h.text}}</span>
                      </template>
                      <span>{{h.tooltip}}</span>
                  </v-tooltip>
                  <span v-else>{{h.text}}</span>
              </span>
          </template>
          <template v-slot:[`header.icon`]="">
              <v-tooltip top>
                  <template v-slot:activator="{ on }">
                      <span v-on="on">
                          <v-icon
                              v-if="anyViewProjectionsWithNotStartedGames || backtesting"
                              style="color: white;"
                              @click="toggleAllExposuresOnOff">
                              {{  getAllExposuresIcon() }}
                          </v-icon>
                      </span>
                  </template>
                  <span>Toggle all exposures on/off</span>
              </v-tooltip>
          </template>
          <template v-slot:[`item.icon`]="{ item }">
              <v-icon
                  title="Can no longer be modified, the game this player is participating in has started."
                  color="red"
                  style="font-size: 20px;"
                  v-if="item.hasGameStarted && !backtesting"
              >
                mdi-lock
              </v-icon>
              <v-icon
                  @click="toggleExposureOnOff(item)"
                  title="Toggle exposure on/off"
                  :disabled="item.hasGameStarted && !backtesting"
              >
                  {{  getExposureIcon(item) }}
              </v-icon>
              <v-icon
                  @click="toggleProjectionLock(item)"
                  title="Toggle lock/unlock player in lineups"
                  :disabled="item.hasGameStarted && !backtesting"
              >
                  {{ getLockIcon(item) }}
              </v-icon>
              <v-icon title="Like Player" 
                      @click="likeProjection(item)"
                      :color="item.liked ? 'green darken-2' : null"
                      :disabled="item.hasGameStarted && !backtesting">
                  {{ getLikedIcon(item) }}
              </v-icon>
              <v-icon title="Dislike Player"  
                      @click="dislikeProjection(item)"
                      :color="item.disliked ? 'red darken-2' : null"
                      :disabled="item.hasGameStarted && !backtesting">
                  {{ getDislikedIcon(item) }}
              </v-icon>
          </template>
          <template v-slot:[`item.name`]="{ item }">
                {{ item.player.name }}
                <v-icon
                    v-if="item.confirmedStarter && item.player.position === 'P'"
                    title="Confirmed Starting Pitcher"
                    color="green"
                >
                    mdi-check-circle
                </v-icon>
                <v-badge
                    v-if="!item.confirmedStarter && item.projectedStarter && item.player.position === 'P'"
                    color="blue"
                    content="P"
                    label="Probable Starting Pitcher"
                    title="Probable Starting Pitcher"
                    inline
                ></v-badge>
                <v-badge
                    v-if="item.battingOrder"
                    color="green"
                    :content="item.battingOrder"
                    :label="`Batting Order ${item.battingOrder}`"
                    :title="`Batting Order ${item.battingOrder}`"
                    inline
                ></v-badge>
                <v-badge
                    v-if="!item.battingOrder && item.projectedBattingOrder"
                    color="blue"
                    :content="item.projectedBattingOrder"
                    :label="`Probable Batting Order ${item.projectedBattingOrder}`"
                    :title="`Probable Batting Order ${item.projectedBattingOrder}`"
                    inline
                ></v-badge>
          </template>
          <template v-slot:[`item.player.position`]="{ item }">
              {{ slateNumberOfGames === 1 ? item.player.position : item.salary.rosterPosition }}
          </template>
          <template v-slot:[`item.rosterStatus`]="{ item }">
              {{ item.rosterStatus === "Active" || item.rosterStatus === "A" ? "Active" : "Inactive" }}
          </template>
          <template v-slot:[`item.opponent`]="{ item }">
              <span v-if="item.isHomeGame">vs. </span>
              <span v-else>@ </span>
              {{ item.opponent.abbreviation }}
          </template>
          <template v-slot:[`item.projectedPoints.projectedOwnership`]="{ item }">
              {{ Math.round(item.projectedPoints.projectedOwnership) }}%
          </template>
          <template v-slot:[`item.customProjectedPoints.projectedPoints`]="{ item }">
              <EditableValue
                  v-model="item.customProjectedPoints.projectedPoints"
                  :disabled="item.hasGameStarted && !backtesting"
                  @updated="updateCustomProjection" />
          </template>
          <template v-slot:[`item.minExposure`]="{ item }">
              <EditableValue
                  v-model="item.minExposure"
                  :disabled="!canEditExposure(item) && !backtesting"
                  @updated="updateMinExposure" />
          </template>
          <template v-slot:[`item.maxExposure`]="{ item }">
              <v-text-field
                  class="centered-input maxexp"
                  v-model="item.maxExposure"
                  :disabled="!canEditExposure(item) && !backtesting"
                  solo
                  @change="updateMaxExposure(item)"
              ></v-text-field>
          </template>
      </v-data-table>
  </div>
</template>

<script>
import DefaultHeaders from './ProjectionTable/DefaultProjectionHeaders';
import SingleGameProjectionHeader from './ProjectionTable/SingleGameProjectionHeader';
import EditableValue from '../EditableValue.vue';

import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('baseball');

export default {
  name: "Projections",
  components: {
      EditableValue
  },
  props: {
    backtesting: {
        type: Boolean,
        default: false
    }
  },
  data: function () {
      return {
          inputFilter: null,
          playerSearchFilter: null,
          hideNoProjections: true,
          projections: null,
          positionFilter: 'ALL',
          sortBy: 'projectedPoints.projectedPoints',
          sortDesc: true,
      };
  },
  mounted: function() {
      // assign state projections to local projections and subscribe to projection mutations
      this.projections = [...this.$store.state.baseball.projections.data];
      this.positionFilter = this.slate?.numberOfGames > 1 ? 'ALL' : (this.provider.abbreviation == 'DK' ? 'CPT' : 'MVP');
      this.$store.subscribe((mutation, state) => {
          if (mutation.type === 'baseball/UPDATE_PROJECTIONS') {
              this.projections = [...state.baseball.projections.data];
          }
          // When slates change, set our projection filter to the default value depending on number of games of the selected slate
          if (mutation.type === 'baseball/UPDATE_SLATE' || mutation.type === 'baseball/UPDATE_SLATES' || mutation.type === 'baseball/UPDATE_PROVIDER' || mutation.type === 'baseball/UPDATE_PROVIDERS') {
              if (state.baseball.slates.selected?.numberOfGames === 1)
                  this.positionFilter = this.provider.abbreviation == 'DK' ? 'CPT' : 'MVP';
              else
                  this.positionFilter = 'ALL';
          }
      });
  },
  computed: {
      excludeNonProbables: {
        get: function() {
            return this.settings.excludeNonProbables;
        },
        set: function(newValue) {
            this.saveBuildSettings({
                settings: {
                    ...this.settings,
                    excludeNonProbables: newValue,    
                }
            });
        }
      },
      headers() {
          if (this.slateNumberOfGames === 1)
              return SingleGameProjectionHeader;
          return DefaultHeaders(this.positionFilter);
      },
      slateNumberOfGames() {
        if (this.slate)
            return this.slate.numberOfGames;
        return 0;
      },
      anyViewProjectionsWithNotStartedGames() {
        return this.viewProjections.some(p => !p.hasGameStarted);
      },
      viewProjections() {
          if (!this.projections || this.projections.length === 0)
              return [];
          
          var viewable = this.hideNoProjections ? this.projections.filter(p => p.projectedFantasyPoints > 0) : this.projections;
          if (this.positionFilter === 'C/1B')
              viewable = viewable.filter(p => p.salary.rosterPosition.includes('C') || p.salary.rosterPosition.includes('1B'))
          else if (this.positionFilter === 'UTIL')
              viewable = this.slateNumberOfGames > 1 ? 
                viewable.filter(p => p.salary.rosterPosition !== 'P') :
                viewable.filter(p => p.salary.rosterPosition === this.positionFilter);
          else if (this.positionFilter !== 'ALL') {
            if (this.slateNumberOfGames > 1)
              viewable = viewable.filter(p => p.salary.rosterPosition.includes(this.positionFilter));
            else
              viewable = viewable.filter(p => p.salary.rosterPosition === this.positionFilter)
          }
          
          // Now remove non probables if selected. Locked players override this
          if (this.excludeNonProbables)
            viewable = viewable.filter(p => (p.confirmedStarter || p.projectedStarter) && !p.isLocked);

          // Last do player search
          if (this.playerSearchFilter)
              return viewable.filter(v => (v.player && v.player.name.toLowerCase().includes(this.playerSearchFilter.toLowerCase())) || v.team.fullName.toLowerCase().includes(this.playerSearchFilter.toLowerCase()));
          return viewable;
      },
      projectionsWithExposuresOff() {
          // Of the viewable projections, how many have a maxExposure === 0
          return this.viewProjections.filter(p => p.maxExposure === 0).length;
      },
      footerProps() {
          return {
              'items-per-page-options': [
                  10,
                  25,
                  50,
                  100,
                  -1
              ]
          };
      },
      positionFilters() {
        if (this.slateNumberOfGames > 1)
            return ['ALL', ...this.slate.rosterPositions];
        return this.slate.rosterPositions;
      },
      ...mapState({
          provider: state => state.providers.selected,
          slate: state => state.slates.selected,
          loadingProjections: state => state.projections.loading,
          settings: state => state.settings
      })
  },
  methods: {
      rowClass(rowData) {
          return rowData.customProjectedPoints.projectedPoints < this.settings.minimumProjectedPointsToInclude ? 'excl-row' : null;
      },
      canEditExposure(projection) {
          if (projection.hasGameStarted)
            return false;
          return !projection.isLocked;
      },
      getLockIcon(item) {
          return item.isLocked ? "mdi-lock" : "mdi-lock-open-outline";
      },
      getExposureIcon(item) {
          return item.maxExposure > 0 ? "mdi-minus-circle-outline" : "mdi-plus-circle-outline";
      },
      getLikedIcon(item) {
          return item.liked ? "mdi-thumb-up" : "mdi-thumb-up-outline";
      },
      getDislikedIcon(item) {
          return item.disliked ? "mdi-thumb-down" : "mdi-thumb-down-outline";
      },
      getAllExposuresIcon() {
          return this.projectionsWithExposuresOff === this.viewProjections.length ? "mdi-plus-box" : "mdi-minus-box";
      },
      getInjuryStatusColor(status) {
          if (status.value === 'P')
              return '#43A047';
          if (status.value === 'Q')
              return '#1E88E5';
          if (status.value === 'D')
              return '#F57C00';
          return '#D32F2F';
      },
      toggleProjectionLock(item) {
          item.isLocked = !item.isLocked;
          this.save();
          this.$gtag.event('Toggle Lock/Unlock Projection');
      },
      toggleExposureOnOff(item) {
          item.maxExposure = item.maxExposure > 0 ? 0 : this.settings.maxExposure;
          this.save();
          this.$gtag.event('Toggle Exposure On/Off');
      },
      toggleAllExposuresOnOff() {
          if (this.projectionsWithExposuresOff === this.viewProjections.length)
              this.viewProjections.forEach(p => p.maxExposure = this.settings.maxExposure);
          else
              this.viewProjections.forEach(p => p.maxExposure = 0);
          this.save();
          this.$gtag.event('Toggle All Exposures On/Off');
      },
      likeProjection(item) {
          item.liked = !item.liked;
          item.disliked = false;
          this.save();
          this.$gtag.event('Like Projection');
      },
      dislikeProjection(item) {
          item.disliked = !item.disliked;
          item.liked = false;
          this.save();
          this.$gtag.event('Dislike Projection');
      },
      resetExposures() {
          this.projections.forEach(p => {
              p.minExposure = 0;
              p.maxExposure = this.settings.maxExposure;
              p.customProjectedPoints.projectedPoints = p.projectedPoints.projectedPoints;
          });
          this.save();
          this.$gtag.event('Reset Exposures and Projections');
      },
      validateMaxExposureAndSave(projection) {
          if (projection.maxExposure > this.settings.maxExposure)
              projection.maxExposure = this.settings.maxExposure;
          this.save();
      },
      searchFilter() {
          this.playerSearchFilter = this.inputFilter;
          this.$gtag.event('Player Search Filter Used');
      },
      clearSearchFilter() {
          this.inputFilter = null;
          this.playerSearchFilter = null;
          this.$gtag.event('Clear Player Search');
      },
      updateCustomProjection() {
          this.save();
          this.$gtag.event('Edit Custom Projection');
      },
      updateMinExposure() {
          this.save();
          this.$gtag.event('Edit Minimum Exposure');
      },
      updateMaxExposure(projection) {
          this.validateMaxExposureAndSave(projection);
          this.$gtag.event('Edit Minimum Exposure');
      },
      save() {
          this.saveCustomProjections(this.projections);
      },
      ...mapActions([
          'saveCustomProjections',
          'saveBuildSettings',
      ])
  }
};
</script>
<style scoped>
div.row {
  margin-top: 6px;
  margin-bottom: 6px;
}
button.btn-icon-action {
  float: right;
}
.centered-input >>> input {
  text-align: center;
}
span.v-badge {
  margin-left: 5px;
}
div.v-input--checkbox {
  display: inline-block;
  margin-left: 30px;
}
div.search-box {
  display: inline-block;
  width: 25%;
  margin-left: 30px;
}
</style>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.78rem;
  font-weight: 500;
}
.proj-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding: 0 6px;
}
.proj-table > .v-data-table__wrapper > table > tbody > tr > td:first-child > * {
  margin: 0 2px;
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #145DA0;
  padding: 0 8px;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.theme--light.v-data-table .v-data-table-header th.sortable:hover, 
.theme--light.v-data-table .v-data-table-header th.sortable.active,
.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon,
.theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon {
  color: white;
  font-weight: bold;
}
.theme--light.v-input.v-text-field--solo input {
  color: white;
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: auto;
  padding: 3px;
}
.theme--light.v-input input {
  min-height: auto;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}
.v-text-field__details {
  display: none;
}
table .v-input {
  font-size: 0.8rem;
}
table .v-input__slot {
  margin-bottom: 0px;
}
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #145DA0;
}
.theme--light.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #2E8BC0;
}
tr.excl-row {
  background-color: #EEEBEB;
}
div.v-input.maxexp.v-input--is-disabled div.v-input__slot {
    background: darkslategray !important;
}
</style>