var IconLockColumn = {
    text: "",
    value: "icon",
    width: "150px",
    sortable: false
};

var PlayerNameColumn = {
    text: "Player",
    value: "name",
    sortable: false,
    divider: true
};

var StatusColumn = {
    text: "Status",
    value: "rosterStatus",
    width: "3%",
    divider: true
};

var SalaryColumn = {
    text: "Salary",
    value: "salary.salary",
    width: "3%",
    divider: true
};

var SalaryPositionColumn = {
    text: "Position",
    value: "salary.rosterPosition",
    width: "4%",
    divider: true
};

var PlayerPositionColumn = {
    text: "Position",
    value: "player.position",
    width: "4%",
    divider: true
};

var TeamColumn = {
    text: "Team",
    value: "team.abbreviation",
    width: "3%",
    divider: true
};

var OpponentColumn = {
    text: "Opponent",
    value: "opponent",
    width: "4%",
    sortable: false,
    divider: true
};

var ProjectedOwnColumn = {
    text: "Own%",
    tooltip: "Projected Ownership %",
    value: "projectedPoints.projectedOwnership",
    width: "3%",
    divider: true
};

var ProjectedPointsColumn = {
    text: "Points",
    tooltip: "Projected Fantasy Points",
    value: "projectedPoints.projectedPoints",
    width: "3%",
    divider: true
};

var CustomProjectedPointsColumn = {
    text: "My Proj",
    value: "customProjectedPoints.projectedPoints",
    width: "100px",
    divider: true
};

var ValueColumn = {
    text: "Value",
    value: "value",
    width: "3%",
    divider: true
};

var MinExposureColumn = {
    text: "Min Exp",
    tooltip: "Minimum Exposure",
    value: "minExposure",
    width: "96px",
    divider: true
};

var MaxExposureColumn = {
    text: "Max Exp",
    tooltip: "Maximum Exposure",
    value: "maxExposure",
    width: "96px"
};

var PassYds = {
    text: "PaYDS",
    tooltip: "Passing Yards per Game",
    value: "stats.passingYardsPerGame",
    width: "72px",
    divider: true
};

var PassTds = {
    text: "PaTDS",
    tooltip: "Passing Touchdowns per Game",
    value: "stats.passingTouchdownsPerGame",
    width: "72px",
    divider: true
};

var RushYds = {
    text: "RuYDS",
    tooltip: "Rushing Yards per Game",
    value: "stats.rushingYardsPerGame",
    width: "72px",
    divider: true
};

var RushTds = {
    text: "RuTDS",
    tooltip: "Rushing Touchdowns per Game",
    value: "stats.rushingTouchdownsPerGame",
    width: "72px",
    divider: true
};

var RecYds = {
    text: "ReYDS",
    tooltip: "Receiving Yards per Game",
    value: "stats.receivingYardsPerGame",
    width: "72px",
    divider: true
};

var RecTds = {
    text: "ReTDS",
    tooltip: "Receiving Touchdowns per Game",
    value: "stats.receivingTouchdownsPerGame",
    width: "72px",
    divider: true
};

var Targets = {
    text: "Tar",
    tooltip: "Targets per Game",
    value: "stats.targetsPerGame",
    width: "56px",
    divider: true
};

var ADot = {
    text: "aDOT",
    tooltip: "Average Depth of Target",
    value: "stats.aDOT",
    width: "66px",
    divider: true
};

var ImpliedTouches = {
    text: "iTouch",
    tooltip: "Implied Touches per Game",
    value: "stats.impliedTouchesPerGame",
    width: "72px",
    divider: true
};

var WOPR = {
    text: "WOPR",
    tooltip: "Weighted Opportunity Rating Percent",
    value: "stats.woprPerGame",
    width: "72px",
    divider: true
};

export {
    IconLockColumn,
    PlayerNameColumn,
    StatusColumn,
    SalaryColumn,
    SalaryPositionColumn,
    PlayerPositionColumn,
    TeamColumn,
    OpponentColumn,
    ProjectedOwnColumn,
    ProjectedPointsColumn,
    CustomProjectedPointsColumn,
    ValueColumn,
    MinExposureColumn,
    MaxExposureColumn,
    PassYds,
    PassTds,
    RushYds,
    RushTds,
    RecYds,
    RecTds,
    Targets,
    ImpliedTouches,
    ADot,
    WOPR
};