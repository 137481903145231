import Vue from 'vue'
import VueRouter from 'vue-router'
import FootballHomeView from '../views/football/HomeView.vue'
import BaseballHomeView from '../views/baseball/HomeView.vue'
import StatsView from '../views/football/Stats.vue';
import CallbackPage from '../views/callback-page.vue';
import TeamRosters from '../views/football/TeamRosters.vue';
import FootballBacktesting from '../views/football/Backtesting.vue';
import BaseballBacktesting from '../views/baseball/Backtesting.vue';
import LoggedOut from '../views/LoggedOut.vue';
import Default from '../views/Default.vue';
import Invite from '../views/Invite.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import TermsConditions from '../views/TermsConditions.vue';
import Subscriptions from '../views/Subscriptions.vue';
import PaymentSuccess from '../views/PaymentSuccess.vue';
import AccountDetails from '../views/AccountDetails.vue';

import { authenticationGuard } from "../authentication-guard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/football/stats',
    name: 'stats',
    component: StatsView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/football/team-rosters',
    name: 'teamRosters',
    component: TeamRosters,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/football/backtesting',
    name: 'footballBacktesting',
    component: FootballBacktesting,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/baseball/backtesting',
    name: 'baseballBacktesting',
    component: BaseballBacktesting,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/logged-out',
    name: 'loggedOut',
    component: LoggedOut
  },
  {
    path: '/football',
    name: 'footballHome',
    component: FootballHomeView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/baseball',
    name: 'baseballHome',
    component: BaseballHomeView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/invite',
    name: 'beta-invite',
    component: Invite,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/account',
    name: 'account-details',
    component: AccountDetails,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/plans',
    name: 'plans',
    component: Subscriptions,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/success',
    name: 'payment-success',
    component: PaymentSuccess,
  },
  {
    path: '/terms',
    name: 'terms-conditions',
    component: TermsConditions,
  },
  {
    path: '/privacy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/',
    name: 'default',
    component: Default,
  },
  {
    path: "/callback",
    name: "callback",
    component: CallbackPage,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
