import Axios from 'axios';

export default {
    contestEntries() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            getContests: (provider, slate) => Axios.get(`/football/ContestEntry/contests/${provider}/${slate}`, headers),
            removeContest: (provider, slate, contest) => Axios.delete(`/football/ContestEntry/removeContest/${provider}/${slate}/${contest}`, headers),
            fillContests: (provider, slate, contestIds) => Axios.post(`/football/ContestEntry/fillContests/${provider}/${slate}`, contestIds, headers),
            exportEntries: (providerId, slateId) => Axios.post(`/football/ContestEntry/exportEntries/${providerId}/${slateId}`, null, { 
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}` 
                }
            }),
            uploadEntries: (provider, slate, file) => Axios.post(`/football/ContestEntry/uploadEntries/${provider}/${slate}`, file, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }})
        }
    },
    contestProvider() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            getAll: () => Axios.get('/football/ContestProviders', headers)
        }
    },
    gameSlates() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            get: (year, week, providerId) => Axios.get(`/football/GameSlate/${year}/${week}/${providerId}`, headers),
            getCurrentValues: () => Axios.get(`/football/GameSlate/currentValues`, headers),
        }
    },
    projections() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            get: (providerId, slateId) => Axios.get(`/football/PlayerProjections?providerId=${providerId}&slateId=${slateId}`, headers),
            getLastUpdated: (season, week, slateId) => Axios.get(`/football/PlayerProjections/lastUpdated/${season}/${week}/${slateId}`, headers),
            update: (projection) => Axios.put(`/football/PlayerProjections/update`, projection, headers),
            updateAll: (projections) => Axios.put(`/football/PlayerProjections/updateAll`, projections, headers),
            trigger: (season, week) => Axios.post(`/football/PlayerProjections/triggerProjections/${season}/${week}`, {}, headers)
        }
    },
    lineupOptimizer() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            getLineups: (providerId, slateId) => Axios.get(`/football/LineupOptimizer/GetLineups/${providerId}/${slateId}`, headers),
            build: (settings) => Axios.post('/football/LineupOptimizer', settings, headers),
            export: (providerId, slateId, lineups) => Axios.post(`/football/LineupOptimizer/ExportLineups/${providerId}/${slateId}`, lineups, { 
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}` 
                }
            }),
            getSettings: (providerId, slateId) => Axios.get(`/football/LineupOptimizer/settings?providerId=${providerId}&slateId=${slateId}`, headers),
            getSavedSettings: (providerId, slateId) => Axios.get(`/football/LineupOptimizer/savedSettings?providerId=${providerId}&slateId=${slateId}`, headers),
            deleteSavedSettings: (providerId, slateId, settingsName) => Axios.delete(`/football/LineupOptimizer/savedSettings?providerId=${providerId}&slateId=${slateId}&settingsName=${settingsName}`, headers),
            updateSettings: (settings) => Axios.put('/football/LineupOptimizer/settings', settings, headers),
            updateSavedSettings: (settingsName, settings) => Axios.put(`/football/LineupOptimizer/savedSettings?settingsName=${settingsName}`, settings, headers),
            saveLineup: (providerId, slateId, lineup) => Axios.post(`/football/LineupOptimizer/SaveLineup/${providerId}/${slateId}`, lineup, headers),
            removeSavedLineup: (providerId, slateId, lineup) => Axios.post(`/football/LineupOptimizer/RemoveSavedLineup/${providerId}/${slateId}`, lineup, headers),
            excludeLineup: (providerId, slateId, lineup) => Axios.post(`/football/LineupOptimizer/ExcludeLineup/${providerId}/${slateId}`, lineup, headers),
            removeExcludedLineup: (providerId, slateId, lineup) => Axios.post(`/football/LineupOptimizer/RemoveExcludedLineup/${providerId}/${slateId}`, lineup, headers),
            getStoredLineups: (providerId, slateId) => Axios.get(`/football/LineupOptimizer/storedLineups?providerId=${providerId}&slateId=${slateId}`, headers),
            getBacktestingStats: (providerId, slateId, lineups) => Axios.post(`/football/LineupOptimizer/GetBacktestingStats/${providerId}/${slateId}`, lineups, headers),
        }
    },
    games() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            get: (season, week) => Axios.get(`/football/Games/${season}/${week}`, headers),
            getOdds: (slateId) => Axios.get(`/football/GameOdds/${slateId}`, headers)
        }
    },
    gameStats() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            getByWeek: (season, week) => Axios.get(`/football/GameStats/${season}/${week}`, headers)
        }
    },
    teams() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            getAll: () => Axios.get('/football/Teams', headers),
            getTeamRosters: (season, week, team) => Axios.get(`/football/Teams/roster/${team}?season=${season}&week=${week}`, headers),
            changeDepth: (season, week, team, playerId, depth, position) => Axios.post(`/football/Teams/roster/${team}/changeDepth`,
                {
                    season: season,
                    week: week,
                    playerId: playerId,
                    depth: depth,
                    position: position
                }, headers),
            updateStatus: (season, week, team, playerId, depth, position, injuryStatus, isActive) => Axios.post(`/football/Teams/roster/${team}/updateStatus`,
                {
                    season: season,
                    week: week,
                    playerId: playerId,
                    depth: depth,
                    position: position,
                    injuryStatus: injuryStatus,
                    isActive: isActive
                }, headers),
        }
    },
    stats() {
        const token = localStorage.getItem('bearer_token');
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return {
            getTeam: (season) => Axios.get(`/football/Stats/team/${season}`, headers),
            getPlayer: (season) => Axios.get(`/football/Stats/player/${season}`, headers)
        }
    }
}