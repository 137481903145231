<template>
    <div>
      <v-row class="top-margin">
        <v-col cols="5">
            <v-alert
                v-if="isOverMaximumLineups"
                outlined
                type="error"
                prominent>
                Your current tier allows for up to {{ maximumAllowedBuilds }} lineups. Modify your build settings to the allowed number of lineups OR if you need to build more lineups check out our <RouterLink to="/plans">upgraded tiers here!</RouterLink>
            </v-alert>
            <v-alert
                v-if="!hasValidBuildSettings"
                outlined
                type="error"
                prominent>
                You have some invalid build settings. Please check your build settings tab and fix any errors before building lineups!
            </v-alert>
          <v-btn elevation="2" @click="build" :loading="building" :disabled="buildsDisabled" color="primary">
            Build Lineups
            <template v-slot:loader>
              <span>Building...</span>
            </template>
          </v-btn>
          <div class="search-box" v-if="hasLineups && !building">
            <v-text-field
                  label="Player Search"
                  v-model="inputFilter"
                  v-on:keyup.enter="searchFilter"
                  v-on:blur="searchFilter"
                  append-icon="mdi-close"
                  @click:append="clearSearchFilter"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="6">
          <v-alert v-if="hasAllLineups && !building" type="success">{{ lineupsBuiltMessage }}</v-alert>
          <v-alert v-else-if="building" type="info">Building your lineups! {{ lineupsBuiltMessage }}</v-alert>
          <v-alert v-else-if="hasLineups && !building" type="warning">{{ lineupsBuiltMessage }}</v-alert>
          <v-alert v-else-if="!hasLineups && !building && haveBuiltLineups && !errorBuilding" type="warning">Unable to generate lineups. Please check your build settings, group rules, stacks, and exposures.</v-alert>
          <v-alert v-else-if="errorBuilding && !building" type="error">Ooops, that wasn't supposed to happen. There was an error building lineups! Try undoing the last change you made.</v-alert>
          <v-alert v-else-if="buildDisabledTip" type="info">{{buildDisabledTip}}</v-alert>
        </v-col>
      </v-row>
      <v-row v-if="backtesting && backtestedStats">
        <v-col cols="2">
            <v-card
                class="bt-stat"
                elevation="2"
                outlined
                tile>
                <h4>Percentile Stats</h4>
                <div>1st Percentile: {{ Math.round(backtestedStats.percentile1) }}%</div>
                <div>10th Percentile: {{ Math.round(backtestedStats.percentile10) }}%</div>
                <div>25th Percentile: {{ Math.round(backtestedStats.percentile25) }}%</div>
                <div>Median Percentile: {{ Math.round(backtestedStats.percentile50) }}%</div>
                <div>75th Percentile: {{ Math.round(backtestedStats.percentile75) }}%</div>
                <div>90th Percentile: {{ Math.round(backtestedStats.percentile90) }}%</div>
                <div>99th Percentile: {{ Math.round(backtestedStats.percentile99) }}%</div>
            </v-card>
        </v-col>
        <v-col cols="2">
            <v-card
                class="bt-stat"
                elevation="2"
                outlined
                tile>
                <h4>Actual vs. Projected</h4>
                <div>Percent Above Projected: {{ Math.round(backtestedStats.aboveProjectedPercent * 100) }}%</div>
                <div>Percent Below Projected: {{ Math.round(backtestedStats.belowProjectedPercent * 100) }}%</div>
            </v-card>
        </v-col>
        <v-col cols="2">
            <v-card
                class="bt-stat"
                elevation="2"
                outlined
                tile>
                <h4>Top Finishes</h4>
                <div>Top 10 Finish: {{ backtestedStats.top10 }}</div>
                <div>Top 100 Finish: {{ backtestedStats.top100 }}</div>
                <div>Top 500 Finish: {{ backtestedStats.top500 }}</div>
                <div>Top 1000 Finish: {{ backtestedStats.top1000 }}</div>
            </v-card>
        </v-col>
      </v-row>
      <v-row v-if="hasLineups">
        <v-col cols="6">
          <v-tabs v-model="tabModel">
            <v-tab href="#tab-players"> Players </v-tab>
            <v-tab href="#tab-stacks"> Stacks </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabModel">
            <v-tab-item value="tab-players">
              <BuildExposuresTable :lineups="lineups"
                                   :search-filter="playerSearchFilter"
                                   :backtesting="backtesting" />
            </v-tab-item>
            <v-tab-item value="tab-stacks">
              <StackExposures :lineups="lineups"
                              :games="games"
                              :search-filter="playerSearchFilter" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-row>
              <v-col cols="12" class="lineup-action-buttons">
                  <FillContestsLineupsModal v-if="hasContestsWithEntries"
                    :building-count="settings.numberOfLineupsToBuild"
                    :provider="provider"
                    :contests="contests"
                    @fill-contests="fillContests"
                    @reload-contests="loadContests"
                    @export-entries="exportEntries" />
                  <v-btn small @click="exportLineups" v-if="!backtesting">
                    Export Lineups
                  </v-btn>
                  <div style="float: right; width: 40%; text-align: right; padding-right: 12px;">
                    <SaveBuildSettingsDialog :savedSettings="savedSettings"
                        @set-saved-settings="setSavedSettings" />
                  </div>
              </v-col>
            </v-row>
            <v-col cols="6" v-for="(lineup, idx) in displayLineups" :key="idx">
              <LineupView :lineup="lineup" :backtesting="backtesting" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  import BuildExposuresTable from "@/components/baseball/Builds/BuildExposuresTable";
  import StackExposures from "@/components/shared/Stacks/StackExposures.vue";
  import fileDownload from 'js-file-download';
  import LineupView from "@/components/baseball/LineupView.vue";
  import RestClient from "@/services/BaseballRestClient";
  import SaveBuildSettingsDialog from "@/components/shared/Settings/SaveBuildSettingsDialog.vue"
  import FillContestsLineupsModal from "@/components/shared/Contests/FillContestsLineupsModal.vue"

  import { createNamespacedHelpers } from 'vuex';
  const { mapState, mapActions, mapGetters } = createNamespacedHelpers('baseball');
  
  export default {
    name: "BuildLineups",
    components: {
      BuildExposuresTable,
      StackExposures,
      LineupView,
      SaveBuildSettingsDialog,
      FillContestsLineupsModal
    },
    props: {
      backtesting: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        tabModel: "tab-players",
        inputFilter: null,
        playerSearchFilter: null,
        saveSettingsDialog: false,
        haveBuiltLineups: false,
        backtestedStats: null
      };
    },
    mounted: async function() {
      this.$store.subscribe((mutation) => {
          // Refresh the last updated date when day changes
          if (mutation.type === 'baseball/UPDATE_LINEUPS' && this.backtesting) {
            if (this.hasAllLineups)
                this.getBacktestingStats();
          }
      });
    },
    computed: {
        buildsDisabled() {
            // Builds are disabled when already building or when the slate is locked
            if (this.building || this.slateLocked)
                return true;
            // Builds are disabled when a user is trying to generate more lineups than they can
            if (this.isOverMaximumLineups)
                return true;
            // If build settings are not valid, do not allow builds to continue
            if (!this.hasValidBuildSettings)
                return true;
            return false;
        },
        // Do the build settings have more lineups being generated than allowed by the users tier
        isOverMaximumLineups() {
            return this.settings.numberOfLineupsToBuild > this.maximumAllowedBuilds;
        },
        maximumAllowedBuilds() {
            return this.$store.getters.maxBuildsAllowed;
        },
        hasValidBuildSettings() {
            return this.settingsErrors?.length === 0;
        },
      // Removes excluded lineups
      displayLineups() {
        if (!this.lineups)
          return this.lineups;
        return this.lineups.filter(l => {
          return !this.storedLineups.excludedLineups.some(a => {
            return a.captains.every(e => l.captainSpots.some(s => s.id === e)) &&
              a.stars.every(e => l.stars.some(s => s.id === e)) &&
              a.utils.every(e => l.utils.some(s => s.id === e)) &&
              a.mvps.every(e => l.mvpSpots.some(s => s.id === e)) &&
              a.ps.every(e => l.pitchers.some(s => s.id === e)) &&
              a.csOr1B.every(e => l.catchersOr1B.some(s => s.id === e)) &&
              a.cs.every(e => l.catchers.some(s => s.id === e)) &&
              a.firsts.every(e => l.firstBasemen.some(s => s.id === e)) &&
              a.seconds.every(e => l.secondBasemen.some(s => s.id === e)) &&
              a.thirds.every(e => l.thirdBasemen.some(s => s.id === e)) &&
              a.sSs.every(e => l.shortstops.some(s => s.id === e)) &&
              a.oFs.every(e => l.outfielders.some(s => s.id === e));
          });
        });
      },
      exportedLineups() {
        return this.displayLineups.map(l => {
          return {
            Ps: l.pitchers.map(qb => qb.salary.providerPlayerId),
            CsOr1B: l.catchersOr1B.map(rb => rb.salary.providerPlayerId),
            Cs: l.catchers.map(wr => wr.salary.providerPlayerId),
            Firsts: l.firstBasemen.map(te => te.salary.providerPlayerId),
            Seconds: l.secondBasemen.map(f => f.salary.providerPlayerId),
            Thirds: l.thirdBasemen.map(d => d.salary.providerPlayerId),
            SSs: l.shortstops.map(d => d.salary.providerPlayerId),
            OFs: l.outfielders.map(d => d.salary.providerPlayerId),
            Captains: l.captainSpots.map(d => d.salary.providerPlayerId),
            Stars: l.stars.map(d => d.salary.providerPlayerId),
            Utils: l.utils.map(d => d.salary.providerPlayerId),
            MvpSpots: l.mvpSpots.map(d => d.salary.providerPlayerId),
          }
        })
      },
      hasLineups() {
        return this.displayLineups && this.displayLineups.length > 0;
      },
      hasAllLineups() {
        return this.hasLineups && this.displayLineups.length === Number(this.settings.numberOfLineupsToBuild.toString());
      },
      hasContestsWithEntries() {
        return this.contests && this.contests.length > 0 && this.contests.some(c => c.entries.length > 0);
      },
      lineupsBuiltMessage() {
        let msg = `Built ${this.displayLineups.length} of ${this.settings.numberOfLineupsToBuild} total lineups.`;
        if (!this.hasAllLineups && !this.building)
          msg = `${msg} We were not able to build all of your requested lineups. Please check build settings and stacks to ensure there are no conflicts.`
        return msg;
      },
      games() {
        return this.odds.map(o => o.game);
      },
      slateLocked() {
        return this.games.every(g => g.hasStarted) && !this.isAdmin;
      },
      buildDisabledTip() {
        if (this.slateLocked)
            return 'All games in this slate have started so this slate is locked. Builds are disabled after slates lock.';
        return '';
      },
      isAdmin() {
        return this.$auth0.user[process.env.VUE_APP_AUTH0_ROLES] && this.$auth0.user[process.env.VUE_APP_AUTH0_ROLES].includes('admin');
      },
      ...mapState({
        settings: state => state.settings,
        settingsErrors: state => state.settingsErrors,
        slate: state => state.slates.selected,
        errorBuilding: state => state.lineups.error,
        building: state => state.lineups.building,
        provider: state => state.providers.selected,
        storedLineups: state => state.storedLineups,
        savedSettings: state => state.savedSettings,
        contests: state => state.contests.data,
        odds: state => state.odds.data,
      }),
      ...mapGetters({
        // Includes possible excluded lineups
        lineups: 'builtLineups'
      })
    },
    methods: {
      build() {
        this.buildLineups();
        this.haveBuiltLineups = true;
        this.backtestedStats = null;
        this.$gtag.event('Build Lineups Baseball');
      },
      searchFilter() {
        this.playerSearchFilter = this.inputFilter;
      },
      clearSearchFilter() {
        this.inputFilter = null;
        this.playerSearchFilter = null;
      },
      setSavedSettings(payload) {
        this.setSavedBuildSettings(payload);
      },
      exportLineups() {
        RestClient.lineupOptimizer()
          .export(this.provider.id, this.slate.id, this.exportedLineups)
          .then(response => fileDownload(response.data, `${this.slate.name.replace(' ', '').replace('@', '-')} lineups.csv`));
      },
      fillContests(contestIds, successCallback, errorCallback, completedCallback) {
        RestClient.contestEntries()
          .fillContests(this.provider.id, this.slate.id, contestIds)
          .then((response) => successCallback?.(response))
          .catch(() => errorCallback?.())
          .finally(() => completedCallback?.());
      },
      exportEntries(successCallback) {
        RestClient.contestEntries()
          .exportEntries(this.provider.id, this.slate.id)
          .then(response => {
                fileDownload(response.data, `${this.provider.abbreviation}_${this.slate.contestTypeValue}_${this.slate.start}_ContestEntries.csv`)
                successCallback?.(response);
          });
      },
      getBacktestingStats() {
        var lineupsDto = this.lineups.map(l => {
            return {
                ActualPointsScored: l.allSpots.reduce(
                    (sum, p) => sum + p.actualFantasyPointsScored,
                    0
                ),
                ProjectedPointsScored: l.allSpots.reduce(
                    (sum, p) => sum + p.projectedFantasyPoints,
                    0
                )
            };
        });
        RestClient.lineupOptimizer()
          .getBacktestingStats(this.provider.id, this.slate.id, lineupsDto)
          .then(response => this.backtestedStats = response.data);
      },
      ...mapActions([
        'buildLineups',
        'setSavedBuildSettings',
        'loadContests',
      ])
    },
  };
  </script>
  
  <style scoped>
  .top-margin {
    margin-top: 20px;
  }
  div {
    width: 100%;
  }
  div.row {
      margin-top: 6px;
      margin-bottom: 6px;
  }
  div.search-box {
    display: inline-block;
    width: 75%;
    margin-left: 30px;
  }
  div.lineup-action-buttons button {
    margin: 0 10px;
    clear: both;
  }
  div.bt-stat h4 {
    background-color: #145DA0;
    color: white;
    padding: 2px 4px;
  }
  div.bt-stat div {
    margin: 2px 4px;
  }
  </style>