<template>
    <v-container fluid>
      <!-- Keeping this here in case we want to restrict access later on 
      <div v-if="!userHasAccessRole">
        <NoAccess sport="Baseball" />
      </div>
      -->
      <div v-if="!isAvailable">
        <OutOfSeason :date-available="dateAvailable" />
      </div>
      <div v-else>
        <v-row>
          <v-col cols="2">
            <ContestProviderSelector @change="contestProviderChange"
              :providers="providers.data"
              :selected-provider="providers.selected"
              :disabled="loadingProjections" />
          </v-col>
          <v-col cols="2">
            <DaySelector @change="dayChange"
                :selected-day="selectedDay"
                :slate-days="slateDays"
                :disabled="loadingProjections" />
          </v-col>
          <v-col cols="2">
            <SlateSelector @change="slateChange"
                :slates="slates.data"
                :selectedSlate="slates.selected"
                :disabled="loadingProjections" />
          </v-col>
          <v-col cols="6" class="proj-upd">
            <span v-if="lastUpdated">Projections Last Updated: {{ displayUpdatedDate }}</span>
            <v-btn
                v-if="hasStaleProjections"
                class="ma-2"
                color="primary"
                @click="refreshProjections"
                :loading="loadingProjections"
            >
                Refresh Projections
            </v-btn>
            <v-btn
                v-if="isAdmin"
                class="ma-2"
                color="primary"
                @click="triggerProjections"
                :loading="isTriggering"
            >
                Trigger Projections
            </v-btn>
          </v-col>
        </v-row>
        <div class="odds-container" v-if="!loadingOdds && hasOdds">
          <GameOdds v-for="(odd, idx) in odds" :key="idx" :odd="odd" />
        </div>
        <v-row v-if="showProjections">
          <v-col cols="12">
            <v-tabs v-model="tabModel">
              <v-tab href="#tab-proj"> Projections </v-tab>
              <v-tab href="#tab-rules" :disabled="loadingProjections"> Rules </v-tab>
              <v-tab href="#tab-stacks" :disabled="loadingProjections"> Stacks </v-tab>
              <v-tab href="#tab-settings" :disabled="loadingProjections"> Build Settings </v-tab>
              <v-tab href="#tab-build" :disabled="loadingProjections"> Builds </v-tab>
              <v-tab href="#tab-my-contests" :disabled="loadingProjections"> My Contests </v-tab>
              <v-tab href="#tab-saved-lineups" v-if="hasSavedLineups" :disabled="loadingProjections"> Saved Lineups ({{ storedLineups.savedLineups.length }}) </v-tab>
              <v-tab href="#tab-excluded-lineups" v-if="hasExcludedLineups" :disabled="loadingProjections"> Excluded Lineups ({{ storedLineups.excludedLineups.length }}) </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabModel">
              <v-tab-item value="tab-proj">
                <Projections />
              </v-tab-item>
              <v-tab-item value="tab-rules">
                <BaseballGroupRules />
              </v-tab-item>
              <v-tab-item value="tab-stacks">
                <BaseballStacksBuilder />
              </v-tab-item>
              <v-tab-item value="tab-settings">
                <BaseballBuildSettings />
              </v-tab-item>
              <v-tab-item value="tab-build">
                <BuildLineups />
              </v-tab-item>
              <v-tab-item value="tab-my-contests">
                <BaseballContests />
              </v-tab-item>
              <v-tab-item value="tab-saved-lineups">
                <StoredLineups :stored-lineups="storedLineups.savedLineups" :exportable="true" />
              </v-tab-item>
              <v-tab-item value="tab-excluded-lineups">
                <StoredLineups :stored-lineups="storedLineups.excludedLineups" />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </template>
  
  <script>
  import BuildLineups from "./BuildLineups";
  import BaseballBuildSettings from "@/components/baseball/BaseballBuildSettings.vue";
  import DaySelector from "@/components/shared/DaySelector";
  import ContestProviderSelector from "@/components/shared/ContestProviderSelector";
  import GameOdds from "@/components/baseball/GameOdds";
  import BaseballGroupRules from "@/components/baseball/BaseballGroupRules";
  import BaseballContests from "@/components/baseball/BaseballContests.vue"
  import Projections from "@/components/baseball/Projections";
  import SlateSelector from "@/components/shared/SlateSelector";
  import BaseballStacksBuilder from "@/components/baseball/BaseballStacksBuilder";
  import RestClient from "@/services/BaseballRestClient";
  import StoredLineups from "@/components/baseball/StoredLineups.vue";
  import OutOfSeason from "@/components/OutOfSeason.vue";

  import { createNamespacedHelpers } from 'vuex';
  const { mapActions, mapState } = createNamespacedHelpers('baseball');
  
  export default {
    name: "Home",
    components: {
      DaySelector,
      ContestProviderSelector,
      GameOdds,
      Projections,
      SlateSelector,
      BaseballBuildSettings,
      BuildLineups,
      StoredLineups,
      BaseballGroupRules,
      BaseballStacksBuilder,
      OutOfSeason,
      BaseballContests,
    },
    data: function () {
      return {
        tabModel: "tab-proj",
        lastUpdatedPoll: null,
        lastUpdated: null,
        lastProjectionUpdateDate: null,
        isTriggering: false,
      };
    },
    created: function () {
        this.loadProviders();
    },
    mounted: async function() {
      this.loadCurrentValues(true); // True to set day
      this.loadTeams();
  
      this.lastUpdatedPoll = setInterval(() => {
        this.getLastUpdated();
      }, 60000);
      
      this.$store.subscribe((mutation) => {
          // Refresh the last updated date when day changes
          if (mutation.type === 'baseball/UPDATE_DAY' || mutation.type === 'baseball/UPDATE_SLATES' || mutation.type === 'baseball/UPDATE_SLATE') {
              this.getLastUpdated();
          }
          // When projections are updated, store the current projection update date
          if (mutation.type === 'baseball/UPDATE_PROJECTIONS') {
              if (this.lastUpdated)
                this.lastProjectionUpdateDate = this.lastUpdated;
          }
      });
    },
    beforeDestroy() {
      clearInterval(this.lastUpdatedPoll);
    },
    computed: {
      dateAvailable() {
        var today = new Date();
        if (today.getMonth() >= 11)
          return new Date(today.getFullYear() + 1, 2, 1);
        return new Date(today.getFullYear(), 2, 1);
      },
      isAvailable() {
        var today = new Date();
        return this.isAdmin || (today >= this.dateAvailable && today < new Date(today.getFullYear(), 11, 1));
      },
      isAdmin() {
        return this.isAuthenticated && this.$auth0.user[process.env.VUE_APP_AUTH0_ROLES] && this.$auth0.user[process.env.VUE_APP_AUTH0_ROLES].includes('admin');
      },
      isAuthenticated() {
        return this.$auth0.isAuthenticated;
      },
      showProjections() {
        return this.loadedProjections || this.loadingProjections;
      },
      hasOdds() {
        return this.odds && this.odds.length > 0;
      },
      hasSavedLineups() {
        return this.storedLineups.savedLineups && this.storedLineups.savedLineups.length > 0;
      },
      hasExcludedLineups() {
        return this.storedLineups.excludedLineups && this.storedLineups.excludedLineups.length > 0;
      },
      displayUpdatedDate() {
        if (this.lastUpdated)
        {
          var theDate = new Date(this.lastUpdated);
          return `${theDate.toLocaleDateString('en-US', { year:"numeric", month:"short", day:"numeric" })} ${theDate.toLocaleTimeString('en-US', { hour:"numeric", minute:"numeric" })}`;
        }
        return null;
      },
      hasStaleProjections() {
        return this.lastUpdated > this.lastProjectionUpdateDate;
      },
      ...mapState({
          projections: state => state.projections.data,
          loadedProjections: state => state.projections.loaded,
          loadingProjections: state => state.projections.loading,
          odds: state => state.odds.data,
          loadingOdds: state => state.odds.loading,
          season: state => state.selectedYear,
          day: state => state.selectedDay,
          settings: state => state.settings,
          storedLineups: state => state.storedLineups,
          providers: state => state.providers,
          currentYear: state => state.currentYear,
          selectedYear: state => state.selectedYear,
          selectedDay: state => state.selectedDay,
          slateDays: state => state.slateDays,
          slates: state => state.slates,
      })
    },
    methods: {
      contestProviderChange(value) {
        this.changeProvider(value);
        this.setProjectionsTab();
      },
      dayChange(value) {
        this.changeDay(value);
        this.setProjectionsTab();
      },
      slateChange(value) {
        this.changeSlate(value);
        this.setProjectionsTab();
      },
      setProjectionsTab() {
        this.tabModel = "tab-proj";
      },
      getLastUpdated() {
        if (this.day && this.slates.selected)
            RestClient.projections()
                .getLastUpdated(this.day, this.slates.selected.id)
                .then((response) => {
                    this.lastUpdated = response.data;
                });
      },
      triggerProjections() {
        this.isTriggering = true;
        RestClient.projections()
            .trigger(this.season, this.day)
            .then(() => {
              this.$root.$emit('snackbar', {
                text: 'Projections have been triggered!',
                color: 'success'
              });
            })
            .finally(() => this.isTriggering = false);
      },
      refreshProjections() {
        this.loadOdds();
        this.loadProjections();
      },
      ...mapActions([
        'loadTeams',
        'loadCurrentValues',
        'loadProviders',
        'loadOdds',
        'changeProvider',
        'changeDay',
        'changeSlate',
        'loadProjections',
      ])
    }
  };
  </script>
  <style scoped>
  @media (min-width: 1904px) {
    div.container {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    div.odds-container {
      margin: 10px 0;
      width: 100%;
      height: auto;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      padding-bottom: 10px;
    }
    .proj-upd {
      text-align: right;
      padding: 20px;
      font-size: 14px;
    }
  }
  </style>